import React from "react"
import Header from "./header"
import Footer from "./footer"
import "../assets/components/bootstrap/css/bootstrap.min.css"
import "../assets/scss/theme.scss"

const Layout = ({ className, children }) => {
  return (
    <div className={className}>
      <Header />
      {children}
      <Footer />
    </div>
  )
}

export default Layout

export const gradientPicker = category => {
  // mention full class name, else purge css will delete it.
  switch (category.toLowerCase()) {
    case "testing":
      return "gradient-bg-testing"
    case "general":
      return "gradient-bg-general"
    default:
      return "gradient-bg-integration"
  }
}

export const btnColorPicker = category => {
  // mention full class name, else purge css will delete it.
  switch (category.toLowerCase()) {
    case "testing":
      return "btn-testing"
    case "general":
      return "btn-general"
    default:
      return "btn-primary"
  }
}
